


















import Vue from 'vue';
import TopMenu from '@/components/Menubar/TopMenu.vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import { adminPages } from '@/constants/page/admin-pages';
import Menu from '@/model/menu';

export default Vue.extend({
  name: 'Admin',
  components: {
    TopMenu,
    SearchBar,
  },
  data() {
    return {
      adminPages: adminPages as Menu[],
      selectedPage: {} as Menu,
      searchText: '',
    };
  },
  mounted() {
    this.selectedPage =
      this.adminPages.find((page: Menu) => page.componentName === this.$route.name) ?? this.adminPages[0];
  },
  methods: {
    textFiltering(text: string) {
      this.setFilter(text);
      (this.$refs[this.selectedPage.componentName] as any).textFiltering(text);
    },
    setFilter(text: string) {
      this.searchText = text;
    },
    selectPage(page: Menu) {
      this.selectedPage = page;
      if (this.$route.name !== page.componentName) {
        this.$router.push({ name: page.componentName });
      }
    },
  },
});
